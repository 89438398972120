<template>
    <div>
        <Navbar :page="`Avaliação ${ prova && prova.treinamento && prova.treinamento.nome ? prova.treinamento.nome : '' }`" />
        
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            
            <div v-if="prova && prova.aplicada && prova.resultado" class="px-5 pb-4">
                <h3 class="text-xl font-normal">Avalição {{ prova.treinamento.nome ? prova.treinamento.nome : '' }} aplicada</h3>
                <div class="bg-gray-100 px-4 py-3 w-96 rounded-sm mt-4">
                    <h3 class="text-lg font-semibold">Resultado:</h3>
                    <ul>
                        <li>Prova de: {{ prova.convidado.nome }}</li>
                        <li>{{ prova.convidado.email }}</li>
                    </ul>
                    <br>
                    <h4 class="text-base font-medium my-2">Avaliação: {{ prova.resultado.nota }} pontos / {{ prova.resultado.total }} pontos  </h4>
                    <h4 class="text-base font-medium my-2">Corretas: {{ prova.resultado.acertou }}</h4>
                    <h4 class="text-base font-medium my-2">Parcial: {{ prova.resultado.parcial }}</h4>
                    <h4 class="text-base font-medium my-2" :class="prova.resultado.aprovado ? 'text-green-600' : 'text-red-600'" >{{ prova.resultado.aprovado ? 'Aprovado!' : ''}}</h4>
                </div>
                <router-link v-if="$store.state.user.cliente" to="/" type="button" class="mt-8 inline-block text-sm text-white hover:bg-gray-600 bg-gray-700 rounded py-1 px-4">
                    Voltar
                </router-link>
            </div>
            <div v-if="prova && prova.aplicada && prova.esperandoCorrecao" class="px-5 pb-4">
                <h3 class="text-xl font-normal">Avalição {{ prova.treinamento.nome ? prova.treinamento.nome : '' }} aplicada</h3>
                <h3 class="text-base font-medium my-2">Esperando correção da prova</h3>
                <router-link v-if="$store.state.user.cliente" to="/" type="button" class="inline-block text-sm text-white hover:bg-gray-600 bg-gray-700 rounded py-1 px-4 mt-3">
                    Voltar
                </router-link>
            </div>
            <div v-if="prova && prova.perguntas" class="px-5 pb-4">
                <div v-if="!prova.aplicada">
                    <p class="text-gray-800 text-normal text-lg mb-4"> 
                        Olá {{ prova && prova.convidado ? prova.convidado.nome : '' }} está avaliação servirá para que a {{ empresa && empresa.nome ? empresa.nome  : 'empresa' }} verifique seu grau de assimilação ao conteúdo apresentado no evento {{ prova && prova.treinamento && prova.treinamento.nome ? prova.treinamento.nome : '' }}. <span v-if="!iniciar">Abaixo, se puder (não é obrigatório), atribua o grau de satisfação com a forma como foi apresentado o conteúdo (nota para facilitador/conteúdo ou palestrante do evento).</span> 
                    </p>

                    <div v-if="!iniciar" class="mb-4"> 
                        <h4 class="text-base font-medium my-4">Classifique o facilitador deste evento (não obrigatório)</h4>
                        <star-rating v-model="notaFacilitador" :star-size="40"></star-rating>
                    </div>

                    <h2 class="text-gray-800 text-normal text-xl py-4">
                        Tempo: <b>{{ tempo | moment("HH:mm:ss")}}</b>
                    </h2>
                    <button v-if="!iniciar" @click="iniciarTeste" type="button" class="mt-4 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-700">
                        Iniciar avaliação
                    </button>
                </div>
                <div v-if="iniciar || prova.aplicada">
                    <div v-for="(item, idx) in prova.perguntas" :key="idx" class="grid grid-cols-12 gap-6 my-1 md:my-3 relative">
                        <div class="col-span-12 md:col-span-12">
                            <div class="bg-gray-100 px-4 py-3 rounded-sm">
                                <p class="text-normal text-base"> <b>{{idx + 1}}</b> - {{ item.pergunta.pergunta }}? </p>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-12">
                            <div v-if="item.tipo === 'Verdadeiro ou Falso'">
                                <label class="block text-sm font-medium mb-2">{{ !prova.aplicada ? 'Selecione a resposta correta:': 'Resposta selecionada:' }} </label>
                                <label class="inline-flex items-center">
                                    <input :class="{'opacity-20': prova.aplicada}" :disabled="prova.aplicada ? true : false" type="radio" class="form-radio" :name="`VerdadeiroOuFalso${idx}`" v-model="item.resposta_verdadeiroFalso" :value="true">
                                    <span class="ml-2">Verdadeiro</span>
                                </label>
                                <label class="inline-flex items-center ml-6">
                                    <input :class="{'opacity-20': prova.aplicada}" :disabled="prova.aplicada ? true : false" type="radio" class="form-radio" :name="`VerdadeiroOuFalso${idx}`" v-model="item.resposta_verdadeiroFalso" :value="false">
                                    <span class="ml-2">Falso</span>
                                </label>
                            </div>
                            <div v-if="item.tipo === 'Múltipla escolha'">
                                <label v-if="item.resposta_multiplasOpcoes" class="block text-sm font-medium my-2">{{ !prova.aplicada ? 'Selecione as respostas corretas:': 'Respostas selecionadas:' }} </label>
                                <label v-for="(op, idxopcoes) in item.resposta_multiplasOpcoes" :key="idxopcoes" class="block my-3">
                                    <input :class="{'opacity-20': prova.aplicada}" :disabled="prova.aplicada ? true : false" type="checkbox" class="form-check" v-model="op.resposta">
                                    <span class="ml-2 text-sm">{{ op.opcao }}</span>
                                </label>
                            </div>
                            <div v-if="item.tipo === 'Campo aberto'">
                                <label class="block text-sm font-medium mb-2">Digite a resposta correta: </label>
                                <textarea :disabled="prova.aplicada ? true : false" rows="3" type="text" placeholder="Digite a resposta correta" v-model="item.resposta_campoaberto" name="pergunta" id="pergunta" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                            </div>
                        </div>
                        <div v-if="$store.state.user" class="col-span-12 md:col-span-12">
                            <p v-if="item.acertou && prova.aplicada" class="text-base font-semibold text-green-600" >Resposta correta</p>
                            <p v-if="item.parcial && !item.acertou  && prova.aplicada" class="text-base font-semibold text-yellow-600" >Resposta parcialmente correta</p>
                            <p v-if="!item.acertou && !item.parcial && prova.aplicada" class="text-base font-semibold text-red-600" >Resposta incorreta</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="prova && !prova.aplicada" class="grid grid-cols-2 mt-8">
                <div class="px-5 py-3 text-left sm:px-6">
                    <button v-if="!iniciar" @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="px-5 py-3 text-right sm:px-6">
                    <button v-if="iniciar" @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import StarRating from "vue-star-rating";
export default {
    components: {
        StarRating,
    },
    data() {
        return {
            route: 'avaliacoes',
            prova: null,
            empresa: null,
            iniciar: false,
            notaFacilitador: 0,
            intervaloTempo: null,
            tempo: moment().hours(0).minutes(0).seconds(0).milliseconds(0)
        }
    },
    methods: {
        async start(){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';

            if (id) {
                const req = await this.$http.get(`/get-prova/${id}`);
                this.prova = req.data.prova;
                this.notaFacilitador = req.data.prova.notaFacilitador;
                this.empresa = req.data.empresa;
            }
        },
        async iniciarTeste () {
            this.iniciar = true;
            this.intervaloTempo = setInterval(() => {
                this.tempo = moment(this.tempo.add(1, 'seconds'))
            }, 1000);
        },
        async save(){

            this.prova.tempo = moment(this.tempo).toDate().getTime();
            this.prova.notaFacilitador = this.notaFacilitador;
            clearInterval(this.intervaloTempo);

            const req = await this.$http.put(`/realizacao-prova`, this.prova); 
            if (req.data.success) {
                this.$vToastify.success("Avaliação finalizada!");
                this.start();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
    },
    async beforeRouteLeave (to, from , next) {
        if(this.prova.aplicada) return next(true);
        const answer = window.confirm("Tem certeza que deseja sair desta página ? Avaliação não finalizada");
        if (!answer) {
            next(false)
        } 
        next(true);
    },
    async beforeMount() {
        this.start();
    },
    beforeDestroy() {
        clearInterval(this.intervaloTempo);
    }
}
</script>